<template>
	<div>
		<div v-if="layout === 'grid'">
			<v-card class="rounded-lg overflow-hidden text-truncate" ref="container">
				<ImageField :value="product.image" :style="{height: width * 0.6 + 'px'}" />
				<v-card-title class="pb-0 font-weight-bold">
					<div class="text-truncate sub-title-2">{{ product.name }}</div>
				</v-card-title>
				<div class="px-4 pb-4 primary--text font-weight-bold title-3" v-html="$options.filters.formatPrice(product.price, '€')"></div>
				<v-row class="mx-0" no-gutters>
					<v-col cols="12">
						<Button
								block
								tile
								flat
								:label="$tc('global.name.details', 2)"
								iconVal="mdi-magnify-plus"
								iconSmall
								iconPosition="right"
								noWrap
								:click="() => $router.push('/marketplace/product/' + product.id)"
								color="cell2"
								medium
						/>
					</v-col>
					<v-col cols="12">
						<Button
								block
								tile
								flat
								color="primary"
								:label="$t('global.action.add_cart')"
								iconVal="mdi-cart"
								iconSmall
								iconPosition="right"
								:loading="loading"
								:click="() => addToCart()"
								medium
						/>
					</v-col>
				</v-row>
			</v-card>
		</div>

		<div v-if="layout === 'list'">
			<v-row class="elevation-2 rounded-lg cell overflow-hidden mx-0 flex-nowrap">
				<v-col cols="auto" class="pa-0" style="max-width: 200px; min-width: 200px; width: 200px;">
					<ImageField :value="product.image" :style="{height: '100%'}" />
				</v-col>
				<v-col style="max-width: calc(100% - 500px)">
					<div class="font-weight-bold sub-title-2">{{ product.name }}</div>
					<div v-if="product.category" class="sub-title-2 label--text text-truncate">
						<span v-if="product.category.length > 0" v-for="(cate, i) in product.category" :key="i">
							<span>{{ cate.display_name }}</span>
							<span v-if="i < product.category.length -1" class="px-4">|</span>
						</span>
						<span v-if="product.category.length === 0">{{ $t('global.catalog.no_cat') }}</span>
					</div>
					<HtmlArea  class="sub-title-2 " :content="product.description" />
				</v-col>
				<v-divider vertical class="my-4"/>
				<v-col cols="auto" class="py-0 text-center" style="max-width: 300px;">
					<v-row>
						<v-col class="pb-0">
							<div class="pt-0 primary--text font-weight-bold title-3" v-html="$options.filters.formatPrice(product.price, '€')"></div>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="pb-0" cols="12">
							<Button
									block
									:label="$tc('global.name.details', 2)"
									iconVal="mdi-magnify-plus"
									iconSmall
									:iconPosition="'right'"
									noWrap
									:click="() => $router.push('/marketplace/product/' + product.id)"
									color="cell2"
									medium
							/>
						</v-col>
						<v-col cols="12">
							<Button
									block
									color="primary"
									:label="$t('global.action.add_cart')"
									iconVal="mdi-cart"
									iconSmall
									:iconPosition="'right'"
									noWrap
									:loading="loading"
									:click="() => addToCart()"
									medium
							/>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</div>

		<div v-if="layout === 'single'">
			<v-row class="mx-0" >
				<v-col cols="12" sm="5" md="4" lg="3" xl="2" ref="container" class="pt-4 pr-0 pl-4">
					<ImageField :value="product.image" class="rounded-lg overflow-hidden" ratio="12" />
				</v-col>
				<v-col cols="12" sm="12" md="8" xl="9" class="pb-8">
					<v-row class="mx-0">
						<v-col cols="12" class="py-0">
							<div class="title-2 font-weight-bold pt-2 pb-4" style="font-weight: 500">{{ product.name | firstCapital }}</div>
							<div class="sub-title-2">{{ $tc('global.product.by', 1) }} : <span style="font-weight: 600">{{ product.provider ? product.provider.display_name : '' }}</span></div>
							<v-row no-gutters class="sub-title-2 mx-0" v-if="product.category">
								<v-col cols="auto" class="pr-2">{{ $tc('global.name.category', 1) +' : ' }}</v-col>
								<v-col style="font-weight: 600" >
									<v-row no-gutters v-if="product.category.length > 0" v-for="(cate, i) in product.category" :key="i">{{ cate.display_name }}</v-row>
									<span v-if="product.category.length === 0">{{ $t('global.catalog.no_cat') }}</span>
								</v-col>
							</v-row>
							<v-divider class="mt-3 mb-3 mx-4" />
							<HtmlArea :full="true" :content="product.description" class="mt-2" classes="px-0" />
							<v-divider class="mt-4 mb-2 mx-4" />
							<div class="paragraph pt-2">{{ $tc('global.product.pre_tax_price', 0) }}</div>
							<div class="title-2 primary--text" style="font-weight: 600" v-html="$options.filters.formatPrice(product.price, '€')"></div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<v-row class="mx-0" justify="center">
				<v-col class="cell3 ">
					<v-row class="mx-0">
						<v-col cols="12" class="sub-title-1">{{ $tc('global.name.extension', 2) }}</v-col>
						<v-col cols="12" :class="!product.extensions ? 'pa-0':''">
							<Extension
									v-if="product.extensions ? product.extensions.length > 0 ? true : false : false"
									v-for="(ext, i) in product.extensions"
									:key="i"
									:product="product"
									v-model="product.extensions[i]"
									:last="i === product.extensions.length -1"
							/>
							<v-row v-if="product.extensions ? product.extensions.length === 0 ? true : false : true" class="mx-0 caption-1">
								<v-col class="pt-0 pl-0 paragraph">{{ $t('single_trad.SingleProduct') }}</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Product",
		props: ['product', 'layout'],
		components: {
			Button: () => import('@/components/ui/Button.vue'),
			ImageField: () => import('@/components/fields/ImageField.vue'),
			Extension: () => import('@/components/Extension.vue'),
			HtmlArea: () => import('@/components/fields/HtmlArea.vue')
		},
		data(){
			return {
				width: 0,
				loading: false,
				label: false
			}
		},
		watch: {
			"$vuetify.breakpoint.width"(){
				if(this.layout === 'grid' || this.layout === 'single')
					this.width = this.$refs.container.$el ? this.$refs.container.$el.clientWidth : this.$refs.container.clientWidth
			}
		},
		methods: {
			addToCart(){
				this.loading = true
				let pcount = 1

				if (!isNaN(this.product.count))
					pcount = this.product.count

				this.$wsc.createItem('cart', { count: pcount, product: this.product.id }, success => {
					let extension_selected = 0
					if (this.product.extensions && this.product.extensions.length > 0){

						this.product.extensions.forEach(e => {
							if(e.count > 0){
								extension_selected++
							}
						})
						if(extension_selected > 0){
							this.$wsc.getList('cart/' + success.id + '/extension', {}, extensions => {
								success.extensions = []

								this.product.extensions.forEach(ext => {
									if(ext.count > 0){
										extensions.forEach(e => {
											if(e.extension.id === ext.id){
												this.$wsc.patchItem('cart/' + success.id + '/extension', e.id, { extension: e.extension.id, count: ext.count }, r => {
													success.extensions.push(r)
													this.$store.dispatch('cart/addItem', success)

													extension_selected--

													if(extension_selected === 0){
														this.loading = false
													}
												}, fail => {

												})
											}
											else {
												success.extensions.push(e)
											}
										})
									}
								})
							}, fail => {

							})
						}
					}
					else {
						this.$store.dispatch('cart/addItem', success)
						this.loading = false
					}
					
					this.$store.dispatch('snackbar/success', this.$t('snackbar.Product.addItem.success'))
				}, fail => {
					this.loading = false
				})
			}
		},
		mounted() {
			if(this.layout === 'grid' || this.layout === 'single')
				this.width = this.$refs.container.$el ? this.$refs.container.$el.clientWidth : this.$refs.container.clientWidth
		}
	}
</script>

<style scoped>

</style>
